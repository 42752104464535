import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Survey } from "src/api/backend-api-types"

export interface SurveyState {
  currentSurveyId?: number
  selectedSectionId?: number
  survey?: Survey
  sectionAnswers?: Record<number, number>
  sectionImages?: any[]
}

export interface SetSectionAnswersInput {
  sectionId: number
  answers?: Record<number, number>
}

export interface SetAnswerInput {
  questionId: number
  answerId: number
}

const initialState: SurveyState = {}

export const surveySlice = createSlice({
  name: "survey",
  initialState,
  reducers: {
    setCurrentSurveyId: (state, action: PayloadAction<number>) => {
      state.currentSurveyId = action.payload
      if (state.survey?.id !== action.payload) {
        state.survey = undefined
        state.selectedSectionId = undefined
        state.sectionAnswers = undefined
      }
    },
    setCurrentSurvey: (state, action: PayloadAction<Survey | undefined>) => {
      const isSwitchingToNewSurvey = state.survey?.id !== action.payload?.id
      state.survey = action.payload

      if (isSwitchingToNewSurvey) {
        state.selectedSectionId = action.payload
          ? action.payload.sections[0].id
          : undefined
        state.sectionAnswers = undefined
      }
      state.currentSurveyId = action.payload ? action.payload.id : undefined
    },
    setAnswer: (state, action: PayloadAction<SetAnswerInput>) => {
      const o = state.sectionAnswers || {}
      const { questionId, answerId } = action.payload
      o[questionId] = answerId
      state.sectionAnswers = o
    },
    setSectionAnswers: (
      state,
      action: PayloadAction<SetSectionAnswersInput>,
    ) => {
      const { sectionId, answers } = action.payload
      if (sectionId != state.selectedSectionId) {
        return
      }

      state.sectionAnswers = answers
    },
    setSectionImages: (state, action: PayloadAction<any[] | undefined>) => {
      state.sectionImages = action.payload
    },
    selectSectionById: (state, action: PayloadAction<number>) => {
      state.selectedSectionId = action.payload
      state.sectionAnswers = undefined
    },
  },
})

export const surveyActions = surveySlice.actions
export default surveySlice.reducer
