import { BaseOptionData, BaseQuestionData } from "src/api/backend-api-types"
import AnswerItem from "./AnswerItem"
import { useSelector } from "react-redux"
import { useCallback, useMemo } from "react"
import { useAppDispatch } from "src/app/hooks"
import { surveyActions } from "../surveySlice"
import { useSaveAnswerMutation } from "src/api/backend-api"
import { RootState } from "src/app/store"

interface AnswerGroupProps {
  question: BaseQuestionData
}

export default (props: AnswerGroupProps) => {
  const answers = useSelector((state: RootState) => state.survey.sectionAnswers)
  const selectedSectionId = useSelector(
    (state: RootState) => state.survey.selectedSectionId,
  )
  const [saveAnswer, { isLoading: isSaving }] = useSaveAnswerMutation()

  const dispatch = useAppDispatch()

  const selectedAnswerId = useMemo(
    () => (answers || {})[props.question.id],
    [answers],
  )

  const handleAnswer = useCallback(
    (answer: BaseOptionData) => {
      if (!selectedSectionId) {
        return
      }

      dispatch(
        surveyActions.setAnswer({
          questionId: props.question.id,
          answerId: answer.id,
        }),
      )

      saveAnswer({
        surveySectionId: selectedSectionId,
        answerId: answer.id,
      })
    },
    [dispatch, props.question.id, selectedSectionId, isSaving],
  )

  return (
    <div className="answerContainer">
      {props.question.options?.map((option) => (
        <AnswerItem
          answer={option}
          key={`${props.question.id}-${option.id}`}
          clickHandler={handleAnswer}
          isSelected={option.id === selectedAnswerId}
          disabled={isSaving}
        />
      ))}
    </div>
  )
}
