interface FreeTextItemProps {
  sectionId: number
}

export default (_: FreeTextItemProps) => {
  return (
    <div>
      <p>
        <strong>Any other comments?</strong>
      </p>
      <textarea rows={5}></textarea>
    </div>
  )
}
