import { useSelector } from "react-redux"
import "./App.css"
import { RootState } from "./app/store"
import SurveyHome from "./features/survey/components/SurveyHome"
import Home from "./features/home/Home"
import SurveyMenu from "./features/survey/components/SurveyMenu"

function App() {
  const currentSurveyId = useSelector(
    (state: RootState) => state.survey.currentSurveyId,
  )

  const reload = () => {
    location.reload()
  }

  return (
    <div className="App">
      <h1 onClick={reload}>Survey Manager</h1>
      {currentSurveyId ? (
        <SurveyHome />
      ) : (
        <>
          <SurveyMenu />
          <Home />
        </>
      )}
    </div>
  )
}

export default App
