import { BaseOptionData } from "src/api/backend-api-types"

interface AnswerItemProps {
  answer: BaseOptionData
  isSelected: boolean
  clickHandler: (answer: BaseOptionData) => void
  disabled: boolean
}

export default (props: AnswerItemProps) => {
  return (
    <div
      className={`card-button ${props.isSelected && "active"}`}
      onClick={() => {
        if (!props.disabled) {
          props.clickHandler(props.answer)
        }
      }}
    >
      {props.disabled ? 'Saving...' : props.answer.value}
    </div>
  )
}
