import QuestionGroup from "./QuestionGroup"
import FreeTextItem from "./FreeTextItem"
import ImageUploader from "./ImageUploader"
import { useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"
import Duplicator from "./Duplicator"
import { useGetSurveySectionDataQuery } from "src/api/backend-api"
import { useAppDispatch } from "src/app/hooks"
import { surveyActions } from "../surveySlice"

export default () => {
  const survey = useSelector((state: RootState) => state.survey.survey)
  const dispatch = useAppDispatch()
  const selectedSectionId = useSelector(
    (state: RootState) => state.survey.selectedSectionId,
  )

  const { currentData } = useGetSurveySectionDataQuery(selectedSectionId!, {
    skip: !selectedSectionId,
  })

  useEffect(() => {
    if (!currentData) {
      return
    }
    const answers = currentData.answers.reduce((curr, item) => {
      return { ...curr, [item.baseQuestionId]: item.baseAnswerId }
    }, {})
    dispatch(
      surveyActions.setSectionAnswers({
        sectionId: currentData.surveySectionId,
        answers,
      }),
    )
    dispatch(surveyActions.setSectionImages(currentData.images || []))
  }, [currentData])

  const surveySection = useMemo(() => {
    if (!survey || !selectedSectionId) {
      return null
    }
    return survey.sections.find((s) => s.id === selectedSectionId)
  }, [survey, selectedSectionId])

  const sectionBaseData = useMemo(() => {
    if (!survey || !surveySection) {
      return null
    }
    return survey.baseData.sections.find(
      (data) => data.id === surveySection.baseId,
    )
  }, [survey, surveySection])

  if (!surveySection || !sectionBaseData) {
    return null
  }

  return (
    <div className="vertContainer">
      <h2>{surveySection.title}</h2>
      <ImageUploader
        surveySectionId={surveySection.id}
        images={[]}
        definition={sectionBaseData.imageDefinition}
      />
      <hr />
      {sectionBaseData.questions?.length && (
        <QuestionGroup questions={sectionBaseData.questions} />
      )}
      {sectionBaseData.hasFreeText && (
        <FreeTextItem sectionId={surveySection.id} />
      )}
      {sectionBaseData.canDuplicate && <Duplicator />}
    </div>
  )
}
