import React, { useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useSaveImageMutation } from "src/api/backend-api"
import { RootState } from "src/app/store"

export const ImageUploadComponent: React.FC = () => {
  const selectedSectionId = useSelector(
    (state: RootState) => state.survey.selectedSectionId,
  )
  const [image, setImage] = useState<string | null>(null)
  const [isReading, setIsReading] = useState(false)
  const [currentFile, setCurrentFile] = useState<File | undefined>()

  const [saveImage, { isLoading, error }] = useSaveImageMutation()

  const handleImageChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0]
      setCurrentFile(file)

      if (file) {
        const reader = new FileReader()
        setIsReading(true)
        reader.onprogress = (ev) => {
          console.log("progress", ev.lengthComputable, ev.loaded, ev.total)
        }
        reader.onloadend = () => {
          setImage(reader.result as string)
          setIsReading(false)
        }
        reader.readAsDataURL(file)
      }
    },
    [setCurrentFile, setIsReading, setImage],
  )

  useEffect(() => {
    if (currentFile && image && saveImage && selectedSectionId) {
      const formData = new FormData()
      formData.append("surveySectionId", `${selectedSectionId}`)
      formData.append("image", currentFile) // needs to go last

      saveImage({
        formData,
        surveySectionId: selectedSectionId,
      })
    }
  }, [currentFile, image, saveImage, selectedSectionId])

  return (
    <div>
      {image ? (
        <img src={image} alt="Uploaded" style={{ width: 200, height: 200 }} />
      ) : (
        <input type="file" accept="image/*" onChange={handleImageChange} />
      )}
      {isLoading && <p>uploading...</p>}
      {error && <p>Upload error: {JSON.stringify(error)}</p>}
    </div>
  )
}
