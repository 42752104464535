import QuestionItem from "./QuestionItem"
import { BaseQuestionData } from "src/api/backend-api-types"

interface QuestionGroupProps {
  questions: BaseQuestionData[]
}

export default (props: QuestionGroupProps) => {
  return props.questions.map((question) => (
    <QuestionItem question={question} key={`${question.id}`} />
  ))
}
