import { useCallback, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"
import { useAppDispatch } from "src/app/hooks"
import { surveyActions } from "../surveySlice"

export default () => {
  const [showMenu, setShowMenu] = useState(false)
  const dispatch = useAppDispatch()
  const survey = useSelector((state: RootState) => state.survey.survey)
  const selectedId = useSelector(
    (state: RootState) => state.survey.selectedSectionId,
  )
  const sectionList = useMemo(() => survey?.sections || [], [survey])

  const handleClick = useCallback(
    (surveySectionId: number) => {
      dispatch(surveyActions.selectSectionById(surveySectionId))
      setShowMenu(false)
    },
    [dispatch, setShowMenu],
  )

  return (
    <nav>
      <p className="sectionMenuLink" onClick={() => setShowMenu((s) => !s)}>&gt;&gt; Section Menu &lt;&lt;</p>
      {showMenu && (
        <ul className="menuList">
          {sectionList.map((section) => (
            <li
              key={section.id}
              className={`${selectedId === section.id ? "active" : ""}`}
              onClick={() => handleClick(section.id)}
            >
              {section.title}
            </li>
          ))}
        </ul>
      )}
    </nav>
  )
}
