import { useCallback } from "react"
import { useGetAllSurveysQuery } from "src/api/backend-api"
import { useAppDispatch } from "src/app/hooks"
import { surveyActions } from "../surveySlice"
import { isoToHumanDate } from "src/lib/utils"

export default () => {
  const { data, isLoading } = useGetAllSurveysQuery()
  const dispatch = useAppDispatch()
  const handleClick = useCallback(
    (surveyId: number) => {
      dispatch(surveyActions.setCurrentSurveyId(surveyId))
    },
    [dispatch],
  )

  if (isLoading) {
    return <p>Loading existing surveys...</p>
  }

  if (!data) {
    return null
  }

  return (
    <div>
      <h3>Existing Surveys</h3>
      <ol className="menuList">
        {data.map((survey, index) => (
          <li onClick={() => handleClick(survey.id)} key={survey.id}>
            {survey.type} [{survey.id}], created {isoToHumanDate(survey.createdAt)}
          </li>
        ))}
      </ol>
    </div>
  )
}
