import { useCallback } from "react"
import { useSelector } from "react-redux"
import { useGenerateWordDocMutation } from "src/api/backend-api"
import { RootState } from "src/app/store"

export default () => {
  const surveyId = useSelector(
    (state: RootState) => state.survey.currentSurveyId,
  )
  const [generate, { isLoading, error, data }] = useGenerateWordDocMutation()

  const handleClick = useCallback(() => {
    if (surveyId) {
      generate(surveyId)
    }
  }, [surveyId, generate])
  return (
    <>
      <button onClick={handleClick} disabled={isLoading}>
        {isLoading ? "Generating..." : "Generate Survey as Word File"}
      </button>
      {error && <p>Error: {JSON.stringify(error)}</p>}
      {data?.url && (
        <p>
          <a href={data.url}>Download generated Word doc</a>
        </p>
      )}
    </>
  )
}
