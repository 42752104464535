import { useCallback, useMemo } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "src/app/hooks"
import { RootState } from "src/app/store"
import { surveyActions } from "../surveySlice"

export default () => {
  const selectedId = useSelector(
    (state: RootState) => state.survey.selectedSectionId,
  )

  const sectionList = useSelector(
    (state: RootState) => state.survey.survey?.sections,
  )

  const dispatch = useAppDispatch()

  const navInfo = useMemo(() => {
    if (selectedId === undefined || !sectionList || sectionList.length <= 1) {
      return { previous: null, current: null, next: null }
    }

    const selectedIndex = sectionList.findIndex(
      (section) => section.id === selectedId,
    )

    const isLastItem = selectedIndex === sectionList.length - 1
    const isFirstItem = selectedIndex === 0

    return {
      previous: isFirstItem ? null : sectionList[selectedIndex - 1],
      current: sectionList[selectedIndex],
      next: isLastItem ? null : sectionList[selectedIndex + 1],
    }
  }, [selectedId, sectionList])

  const goPrevious = useCallback(() => {
    if (!navInfo.previous) {
      return
    }
    dispatch(surveyActions.selectSectionById(navInfo.previous.id))
  }, [navInfo, dispatch])

  const goNext = useCallback(() => {
    if (!navInfo.next) {
      return
    }
    dispatch(surveyActions.selectSectionById(navInfo.next.id))
  }, [navInfo, dispatch])

  return (
    <div className="nextNav">
      {navInfo.previous && (
        <p className="link previous" onClick={goPrevious}>
          &lt; &lt; {navInfo.previous.title}
        </p>
      )}
      {navInfo.next && (
        <p className="link next" onClick={goNext}>
          {navInfo.next.title} &gt; &gt;
        </p>
      )}
    </div>
  )
}
