import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import counterReducer from "src/features/counter/counterSlice"
import surveyReducer from "src/features/survey/surveySlice"
import { backendApi } from "src/api/backend-api"

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    survey: surveyReducer,
    [backendApi.reducerPath]: backendApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(backendApi.middleware),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
