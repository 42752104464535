import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { BACKEND_API_URL } from "src/lib/config"
import {
  DuplicateSectionResponse,
  GenerateWordDocResponse,
  SaveAnswerInput,
  SaveAnswerResponse,
  SaveImageInput,
  Survey,
  SurveySectionData,
} from "./backend-api-types"

export const backendApi = createApi({
  reducerPath: "backendApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BACKEND_API_URL,
    prepareHeaders: (headers, api) => {
      console.log("prep headers", api.endpoint)
      if (api.endpoint !== "saveImage") {
        headers.set("Content-type", "application/json")
      }
      return headers
    },
  }),
  tagTypes: ["Survey", "SurveySectionData"],
  endpoints: (builder) => ({
    getAllSurveys: builder.query<Survey[], void>({
      query: () => "surveys",
      providesTags: [{ type: "Survey", id: "LIST" }],
    }),
    getSurvey: builder.query<Survey, number>({
      query: (id) => `survey/${id}`,
      transformResponse: (response: { survey: Survey }) => {
        return response.survey
      },
      providesTags: (result) => {
        return result ? [{ type: "Survey", id: result.id }] : []
      },
    }),
    createNewSurvey: builder.mutation<Survey, void>({
      query: (payload) => ({
        url: "/survey",
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: [{ type: "Survey", id: "LIST" }],
    }),
    duplicateSection: builder.mutation<DuplicateSectionResponse, number>({
      query: (payload) => ({
        url: "/survey/section/duplicate",
        method: "POST",
        body: { sectionId: payload },
      }),
      invalidatesTags: (result) => {
        return result ? [{ type: "Survey", id: result.surveyId }] : []
      },
    }),
    saveAnswer: builder.mutation<SaveAnswerResponse, SaveAnswerInput>({
      query: (payload) => ({
        url: "/answer",
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: (result) => {
        return result
          ? [{ type: "SurveySectionData", id: result.surveySectionId }]
          : []
      },
    }),
    getSurveySectionData: builder.query<SurveySectionData, number>({
      query: (payload) => `survey-section-data/${payload}`,
      providesTags: (result) => {
        return result
          ? [{ type: "SurveySectionData", id: result.surveySectionId }]
          : []
      },
    }),
    saveImage: builder.mutation<void, SaveImageInput>({
      query: (payload) => ({
        url: `/image`,
        method: "PUT",
        body: payload.formData,
      }),
    }),
    generateWordDoc: builder.mutation<GenerateWordDocResponse, number>({
      query: (payload) => ({
        url: `/generate`,
        method: "POST",
        body: { surveyId: payload },
      }),
    }),
  }),
})

export const {
  useGetAllSurveysQuery,
  useGetSurveyQuery,
  useCreateNewSurveyMutation,
  useDuplicateSectionMutation,
  useSaveAnswerMutation,
  useGetSurveySectionDataQuery,
  useSaveImageMutation,
  useGenerateWordDocMutation,
} = backendApi
