import { useCallback } from "react"
import { useCreateNewSurveyMutation } from "src/api/backend-api"
import { useAppDispatch } from "src/app/hooks"
import { surveyActions } from "../survey/surveySlice"

export default () => {
  const dispatch = useAppDispatch()
  const [startNewSurvey, { isLoading, error }] = useCreateNewSurveyMutation()

  const createSurvey = useCallback(async () => {
    const { id } = await startNewSurvey().unwrap()
    if (id) {
      dispatch(surveyActions.setCurrentSurveyId(id))
    }
  }, [dispatch, startNewSurvey])

  return (
    <div>
      {error && (
        <p>
          Error creating new survey:{" "}
          <span color="red">{JSON.stringify(error)}</span>
        </p>
      )}
      <button onClick={createSurvey} disabled={isLoading}>
        {isLoading ? "Creating new survey..." : "Create new survey"}
      </button>
    </div>
  )
}
