import { BaseQuestionData } from "src/api/backend-api-types"
import AnswerGroup from "./AnswerGroup"

interface QuestionItemProps {
  question: BaseQuestionData
}

export default ({ question }: QuestionItemProps) => {
  return (
    <div>
      <p>
        <strong>{question.title}</strong>
      </p>
      <AnswerGroup question={question} />
    </div>
  )
}
