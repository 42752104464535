import {
  BaseSectionImageDefinition,
  SurveySectionImage,
} from "src/api/backend-api-types"
import { ImageUploadComponent } from "./ImageUpload"

interface ImageUploaderProps {
  surveySectionId: number
  images: SurveySectionImage[]
  definition?: BaseSectionImageDefinition
}

export default (_: ImageUploaderProps) => {
  return (
    <div>
      <p>Upload images</p>
      <ImageUploadComponent />
    </div>
  )
}
