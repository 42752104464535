import { useCallback } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "src/app/hooks"
import { RootState } from "src/app/store"
import { useDuplicateSectionMutation } from "src/api/backend-api"
import { surveyActions } from "../surveySlice"

export default () => {
  const selectedSectionId = useSelector(
    (state: RootState) => state.survey.selectedSectionId,
  )

  const [goDuplicate, { isLoading }] = useDuplicateSectionMutation()

  const dispatch = useAppDispatch()

  const handleClick = useCallback(async () => {
    if (selectedSectionId) {
      const { createdSectionId } = await goDuplicate(selectedSectionId).unwrap()
      dispatch(surveyActions.selectSectionById(createdSectionId))
    }
  }, [selectedSectionId, dispatch])

  return (
    <button onClick={handleClick} disabled={isLoading}>
      {isLoading ? "Duplicating..." : "Duplicate Me"}
    </button>
  )
}
