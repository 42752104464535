import SectionMenu from "./SectionMenu"
import SectionItem from "./SectionItem"
import NextNav from "./NextNav"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"
import { useGetSurveyQuery } from "src/api/backend-api"
import { useAppDispatch } from "src/app/hooks"
import { useEffect } from "react"
import { surveyActions } from "../surveySlice"
import SurveyGenerate from "./SurveyGenerate"

export default () => {
  const currentSurveyId = useSelector(
    (state: RootState) => state.survey.currentSurveyId,
  )

  const {
    currentData: currentSurveyData,
    error,
    isLoading,
  } = useGetSurveyQuery(currentSurveyId!, { skip: !currentSurveyId })

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (currentSurveyData) {
      dispatch(surveyActions.setCurrentSurvey(currentSurveyData))
    }
  }, [currentSurveyData, dispatch])

  return (
    <div>
      {
        <p>
          <em>Survey {currentSurveyId}</em>
        </p>
      }
      {isLoading && (
        <p>
          <em>Loading survey data...</em>
        </p>
      )}
      {error && (
        <p>
          Error loading survey: <span color="red">{JSON.stringify(error)}</span>
        </p>
      )}
      {!isLoading && !error && (
        <>
          <SurveyGenerate />
          <SectionMenu />
          <SectionItem />
          <NextNav />
        </>
      )}
    </div>
  )
}
